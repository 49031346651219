import {useEffect, useRef, useState} from 'react';
import {useActionData} from '@remix-run/react';
import {useDuxState} from '@shopify/dux';

import type {HandleFormSubmitOptions} from '@/hooks/useDuxTracking';
import {useFormTracking} from '@/hooks/useDuxTracking';
import type {FormSourceTypes} from '@/enums';
import {ApprovedCookie} from '@/enums';
import {getCookie} from '@/utils/utils';
import {useBrowserUrl} from '@/hooks/useBrowserUrl';

import {useSiteData} from './useSiteData';
import useDataLayer from './useDataLayer';

export type AttributionPropsType = {
  primaryProduct: String;
  source: FormSourceTypes;
};

type UseFormTrackingPropsType = {
  attributionProps: AttributionPropsType;
  eventCategory: string;
  /**
   * Use only if action data is accessed from a different route.
   * Otherwise `useActionData` handles the form submission state internally
   */
  submitResponse?: string;
};

/**
 * @deprecated Leverage the <BaseLeadForm /> to maintain consistent tracking across forms
 */
export function useFormAttributionTracking({
  attributionProps,
  eventCategory,
  submitResponse,
}: UseFormTrackingPropsType) {
  const actionData = useActionData<any>();
  const {canonicalUrl} = useSiteData();
  const [trackingProperties, setTrackingProperties] = useState({} as any);
  const currentURL = useBrowserUrl();
  const [lastStatus, setLastStatus] = useState('');
  const submittedData = useRef({} as any);
  const dataLayer = useDataLayer();

  function successTracker({trackers}: HandleFormSubmitOptions): void {
    const successEventTracking = {
      eventCategory,
      eventAction: 'success',
      event_non_interaction: 'false',
      event: 'form_success',
    };
    const eventData = {
      ...submittedData.current,
      ...trackingProperties,
    };

    if (trackers?.gtm) {
      trackers.gtm(successEventTracking, eventData);
    }
  }

  function errorTracker({trackers}: HandleFormSubmitOptions): void {
    const errorEventTracking = {
      eventCategory,
      eventAction: 'error',
      eventLabel: 'validation failed',
      event_non_interaction: 'false',
      event: 'form_error',
    };

    if (trackers?.gtm) {
      trackers.gtm(errorEventTracking, {});
    }
  }

  const trackSuccessEvent = useFormTracking(successTracker);
  const trackErrorEvent = useFormTracking(errorTracker);

  const {pageViewToken} = useDuxState();

  useEffect(() => {
    setTrackingProperties({
      ...attributionProps,
      experiment_variation_id: dataLayer.experimentVariationId,
      page_view_token: pageViewToken,
      user_token: getCookie(ApprovedCookie.MultiTrackToken),
      session_token: getCookie(ApprovedCookie.SessionToken),
      sign_up_page: canonicalUrl ?? '',
      utm_medium: encodeURIComponent(
        currentURL?.searchParams.get('utm_medium') || '',
      ),
      utm_campaign: encodeURIComponent(
        currentURL?.searchParams.get('utm_campaign') || '',
      ),
      utm_content: encodeURIComponent(
        currentURL?.searchParams.get('utm_content') || '',
      ),
      utm_source: encodeURIComponent(
        currentURL?.searchParams.get('utm_source') || '',
      ),
      utm_term: encodeURIComponent(
        currentURL?.searchParams.get('utm_term') || '',
      ),
      gclid: encodeURIComponent(currentURL?.searchParams.get('gclid') || ''),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect handles a successful clientside validation and post to the server. This is not run on a client side validation failure.
  useEffect(() => {
    if (actionData && lastStatus !== actionData.status) {
      setLastStatus(actionData.status);
    } else if (submitResponse) {
      setLastStatus(submitResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, submitResponse]);

  useEffect(() => {
    if (lastStatus === 'success') {
      trackSuccessEvent();
    } else if (lastStatus === 'error') {
      trackErrorEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStatus]);

  function submitHandler(data: any) {
    submittedData.current = data;
    setLastStatus('');
  }
  return {submitHandler, trackingProperties};
}
